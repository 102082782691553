<template>
  <div class="container-fluid">
    <email-inbox />
  </div>
</template>

<script>
import EmailInbox from "@/views/components/inbox/EmailInbox";
export default {
  components: {EmailInbox}
};
</script>

<style>
</style>
